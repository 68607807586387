<template>
  <v-app>

    <br/>
    <v-container
      id="contrato"
      fluid
      tag="section">
      
     
    <base-material-card
    icon="mdi-history"
    title="Consulta de Log"
    color="green" style="margin-top:0px !important"
    class="px-5 py-0"
  > 
      <v-row>
        <v-col cols="12">
  
          <v-data-table
          :headers="this.headers_vendas"
          :items="lista_dessert"
          :items-per-page="itemsPerPage"
          :items-per-page-text="'Registros por página'" 
          class="elevation-0"
  
        >
  
        <template v-slot:top>
          <v-progress-linear
            v-if="isLoading" 
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>
  
       

        <template v-slot:item="row">
          <tr :class="{'gray-row': row.index % 2 !== 0}">
            <td style="width: 20%; font-size: 14px;">{{ row.item.pagina }}</td>
            <td style="width: 15%; font-size: 14px;">{{ row.item.user }}</td>
            <td style="width: 40%; font-size: 14px;">{{ row.item.filtro }}</td>
            <td style="width: 15%; font-size: 14px;">{{ row.item.acao }}</td>
            <td style="width: 15%; font-size: 14px;">{{ row.item.data_insert }}</td>

        </tr>
      </template> 
   
    
      
  
  
      </v-data-table>
  
     
      
        
        </v-col>  
       
      
    
  
      </v-row>
     
  
    
      </base-material-card>
  
  
    </v-container>
  
  </v-app> 
  </template>
  
  <script>
  import Historico from '../../services/log'

  
    export default {
    
  
      name: 'Contrato',
   
      data () {
        return {
  
          rules: {
            required: value => !!value || 'Obrigatório.',
          },
          
          currentPage: 1, // Página atual
          itemsPerPage: 10, // Itens por página
          pageNumber: 1, // Número da página
          totalPages:0,
          selectedStartDate: "",
          selectedEndDate: "",
          isLoading : false,
         

          headers_vendas: [
           
            { text: 'Página', value: 'pagina',   sortable: false, },
            { text: 'Usuário', value: 'user',   sortable: false, },
            { text: 'Filtro', value: 'filtro',   sortable: false, },
            { text: 'Ação', value: 'acao',   sortable: false, },
            { text: 'Data', value: 'data_insert',   sortable: false, },
          ],
  
          lista_dessert: [],
         

        }
    },
  
    mounted(){
      this.fetchData();
    },


    computed: {
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },   
     
    },
  
  
  
      methods: {
  
        changePage() {
          //this.isLoading = true;
         // this.loadPageData();
         // this.fetchData(this.currentPage);
  
       },
  
       loadPageData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      },
       
      
  
          fetchData() {


              Historico.log()
                .then(response => {
                  if (response.status === 200) {
                    this.lista_dessert = response.data;
                    this.loading = false; // Esconder o loading após a pesquisa
                 
                  }
                })
                .catch(e => {
                  // Lida com erros
                  this.isLoading = false;
                  this.lista_dessert = [];
                })
                .finally(() => {
                  this.isLoading = false;
                });
            }
      }  
    }
  
    function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }
  
  
  </script>
  
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }
  
    .custom-gray-background {
      height: 80px;
    }
    
    .row {
      display: flex;
      flex-wrap: wrap;
       flex: 0 1 auto !important; 
      margin: -12px;
  }
  
  
    /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
    .fill-width {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      display: flex;
    }
    
    /* Centralize horizontalmente */
    .justify-center {
      justify-content: center;
    }
    
    .gray-row {
      background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */
    }
   
    
    </style>
  