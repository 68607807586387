import { http } from './config'
import authHeader from './auth-header';

export default {


    

    log: () => {

          return http.get('/historico/log', {
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
          });

          
    },


}
